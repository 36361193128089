import React from "react";
import { Link } from "gatsby";
import { FaGlobeAfrica } from "react-icons/fa";
import CookieConsent from "react-cookie-consent";

export default data => (
  <div>
    <CookieConsent
      location="bottom"
      buttonText="Jag godkänner"
      cookieName="consentCookie"
      style={{ background: "#2B373B", paddingLeft: "50px" }}
      buttonStyle={{
        backgroundColor: "#25aae3",
        color: "white",
        fontSize: "15px",
        borderRadius: "15px",
        padding: "6px",
      }}
      expires={150}
    >
      Genom att använda denna hemsida, godkänner du vår {" "}
      <span style={{ fontSize: "13px" }}>
        <a style={{ fontSize: "15px", color: "#25aae3" }} href="/gdpr">
          {" "}
          cookie- och integritetspolicy
        </a>.
      </span>
    </CookieConsent>

    <footer className="bg-blue-800 w-full">
      <svg
        className="wave-top"
        viewBox="0 0 1439 147"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
            <g className="wave" fill="white">
              <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
            </g>
            <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
              <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                <path
                  d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                  opacity="0.200000003"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>

      <div className="container mx-auto  px-8 pb-4">
        <div className="w-full flex flex-col md:flex-row py-6">
          <div className="flex-1 mb-6">
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-400 md:mb-6">PSK SYD AB</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  target="_blank"
                  href="https://www.google.com/maps/place/Garnisonsgatan+7A,+254+66+Helsingborg/@56.0719532,12.7205349,17z/data=!3m1!4b1!4m5!3m4!1s0x4652326fb5c79261:0xb0cb1195dc77a716!8m2!3d56.0719502!4d12.7227236"
                  className="no-underline hover:underline text-white hover:text-blue-500"
                >
                  Garnisonsgatan 7A <br></br>
                  254 66 Helsingborg<br></br> Sweden
                </a>
                <br></br>
                <br></br>
                {/* <a
                  target="_blank"
                  href="https://cdn360.psksyd.com/PSK/hittatillpsk/index.html"
                  className="no-underline hover:underline text-white hover:text-blue-500"
                >
                  360º view
                </a> */}
              </li>{" "}
              <br></br>
              <br></br>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <p className="text-white">Org nr: 556477-8222</p>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-400 md:mb-6">Kontakt</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 mr-2 block md:mr-0">
                <p className="text-white">Växel:</p>
                <p className="text-white">+46 (0)42-21 18 12</p>
                <p className="text-white">Öppettider 08:00 - 16:00</p>
                <p className="text-white">Lunchstängt 12:00 - 13:00</p>
              </li>
              <li className="mt-2 mr-2 block md:mr-0">
                <p className="text-white">Felanmälansväxel:</p>
                <p className="text-white">+46 (0)42-188 20 00</p>
                <p className="text-white">Öppettider 08:00 - 16:00</p>
              </li>
              <br></br>
              <li className="mt-2 mr-2 block md:mr-0">
                <a
                  href="mailto:info@psksyd.com"
                  className="no-underline hover:underline text-white hover:text-blue-500"
                >
                  info@psksyd.com
                </a>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-400 md:mb-6">Sociala medier</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="https://www.linkedin.com/company/psk-syd-ab/"
                  target="_blank"
                  rel="noopener"
                  className="no-underline hover:underline text-white hover:text-blue-500"
                >
                  LinkedIn
                </a>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="https://www.facebook.com/PSKSydAB/"
                  target="_blank"
                  rel="noopener"
                  className="no-underline hover:underline text-white hover:text-blue-500"
                >
                  Facebook
                </a>
              </li>
            </ul>
          </div>

          <div className="flex-1">
            <p className="uppercase text-gray-400 md:mb-6">Företaget</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/about/history"
                  className="no-underline hover:underline text-white hover:text-blue-500"
                >
                  Om oss
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/contact"
                  className="no-underline hover:underline text-white hover:text-blue-500"
                >
                  Kontakt
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/career"
                  className="no-underline hover:underline text-white hover:text-blue-500"
                >
                  Karriär
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/gdpr"
                  className="no-underline hover:underline text-white hover:text-blue-500"
                >
                  Integritet och Cookies
                </Link>
              </li>
              {/* CSR - Ssenyange Education Project */}
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  className="no-underline hover:underline text-white hover:text-blue-500"
                  href="https://www.facebook.com/ssenyange.org/"
                  target="_blank"
                >
                  Vi sponsrar Ssenyange
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
);
